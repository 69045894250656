import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: 'https://ghostgroup.tech',  // Измените на ваш домен с https
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

